import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image/withIEPolyfill"
import AutorizadasList from "../components/autorizadas-list"

import { useStaticQuery, graphql, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const Contato = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { eq: "CedilarMapa.jpg" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const mapImg = data?.allFile?.edges[0]?.node?.childImageSharp?.fluid || null

  return (
    <Layout>
      <SEO title="Contato Cedilar POA-RS | Atendimento Por Mensagem WhatsApp" />
      <h1 className="text-2xl leading-tight container flex mx-auto justify-between pt-6">
        Cedilar Assistência Técnica de Eletrodomésticos Porto Alegre - RS
      </h1>
      <div className="md:flex gap-4">
        <div className="w-full md:w-1/2 p-4 md:px-0">
          <a
            href="https://www.google.com/maps/place/CeDilar+Technical+Assistance/@-30.0480295,-51.2167232,17z/data=!3m1!4b1!4m5!3m4!1s0x9519785a3631eabd:0x4ace6c9188c9fb67!8m2!3d-30.0480342!4d-51.2145345"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              className="lazyload max-w-sm mt-4 md:block md:mx-auto"
              objectFit="contain"
              fluid={mapImg}
              alt="Direções Cedilar POA"
            />
          </a>
        </div>
        <div className="w-full md:w-1/2 p-4 md:px-0 mt-4">
          <span>
            <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
            <Link to="/" aria-label="Ir a Página Inicial">
              Av. Ipiranga, 1477 - Azenha, Porto Alegre
            </Link>
          </span>
          <ul className="items-center justify-between sm:justify-end mt-1">
            <li className="mr-3">
              <FontAwesomeIcon icon={faPhone} /> +55 51 3217-9658
            </li>
            <li className="mr-3 mt-1">
              <FontAwesomeIcon
                icon={faWhatsapp}
                className="bg-green-600 rounded-sm text-sm text-white"
              />{" "}
              +55 51 3217-9658
            </li>
            <li className="mt-4 text-sm">
              <strong>Segunda:</strong> 9:00 às 12:00 - 14:00 às 18:00
            </li>
            <li className="text-sm">
              <strong>Terça:</strong> 9:00 às 12:00 - 14:00 às 18:00
            </li>
            <li className="text-sm">
              <strong>Quarta:</strong> 9:00 às 12:00 - 14:00 às 18:00
            </li>
            <li className="text-sm">
              <strong>Quinta:</strong> 9:00 às 12:00 - 14:00 às 18:00
            </li>
            <li className="text-sm">
              <strong>Sexta:</strong> 9:00 às 12:00 - 14:00 às 18:00
            </li>
            <li className="text-sm">
              <strong>Sábado:</strong> Fechado
            </li>
            <li className="text-sm">
              <strong>Domingo:</strong> Fechado
            </li>
          </ul>
        </div>
      </div>
      <AutorizadasList />
    </Layout>
  )
}

export default Contato
